import React, { useEffect, useState, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import axios from 'axios'
import { addAlert } from '@/features/Notifications/notificationSlice'
import RefundItems from './RefundItems'
import { useConfirm } from '@/lib/useConfirmHook'
import { numberToCurrency } from '@/lib/Number'
import { accessToken } from '@/lib/Csrf'
import titleize from '@/lib/String'
import { debug } from '@/lib/Debug'

export default function RefundForm({
    companyId,
    locationId,
    checkNumber,
    payment,
    tabs,
    isTipRefunded,
    isNonSplitBookingRefunded,
    refundedUuids
}) {
    const dispatch                                = useDispatch()
    const { confirm }                             = useConfirm()
    const [refundOption, setRefundOption]         = useState('full')
    const [notes, setNotes]                       = useState('')
    const [email, setEmail]                       = useState('')
    const [methodOptions, setMethodOptions]       = useState([])
    const [selectedMethod, setSelectedMethod]     = useState('credit')
    const [selectedTabItems, setSelectedTabItems] = useState([])
    const [includesTip, setIncludesTip]           = useState(false)
    const [customAmount, setCustomAmount]         = useState(0)

    const noTabsPresent = (tabs || []).length === 0

    const amount = useMemo(() => {
        let total = 0

        if (refundOption === 'full') {
            total += payment.amount_refundable_cents - (isTipRefunded ? 0 : payment.tip_cents)
        } else if (refundOption === 'custom') {
            total += customAmount * 100
        } else {
            total += selectedTabItems.reduce((totalSelected, tab) => {
                totalSelected += (tab.items || []).reduce((sum, item) => sum + item.total_received_cents, 0)
                totalSelected += (tab.booking_items || []).reduce((sum, item) => sum + item.total_received_cents, 0)
                totalSelected += tab.total_received_booking_cents || 0
                return totalSelected
            }, 0)
        }

        if (includesTip) {
            total += payment.tip_cents
        }

        // NOTE
        // the parseFloat/toFixed combo is in here specifically
        // to combat floating point math issues.
        //
        // ex: (0 + 39.55) * 100 = 3954.9999999999995 instead of 3954 as expected
        return Number.parseFloat(total.toFixed(2))
    }, [refundOption, selectedTabItems, includesTip, customAmount])

    const processRefund = (advPosTransactionSplits) => {
        axios.post(
            `/companies/${companyId}/locations/${locationId}/pos/checks/${checkNumber}/payments/${payment.id}/refunds`,
            {
                authenticity_token: accessToken,
                refund_option: refundOption,
                amount_cents: amount,
                includes_tip: includesTip,
                email: email || payment.email,
                notes: notes,
                refund_method: selectedMethod,
                adv_pos_transaction_splits: advPosTransactionSplits
            }
        )
        .then(({ data }) => {
            if (data.success) {
                window.location.href = `/companies/${companyId}/locations/${locationId}/pos/checks/${checkNumber}`
                dispatch(addAlert({ type: 'success', text: 'Refund Processed' }))
            } else {
                dispatch(addAlert({ type: 'error', text: data.message }))
            }
        })
    }

    const handleProcessRefund = async (e) => {
        e.preventDefault()
            if (debug && console) { console.error(`amount: ${amount} | refundable amount: ${payment.amount_refundable_cents}`) }

        if (amount > payment.amount_refundable_cents) {
            dispatch(addAlert({ type: 'error', text: `Refund amount cannot be more than ${numberToCurrency(payment.amount_refundable_cents / 100)}` }))
            if (debug && console) { console.error(`amount: ${amount} | refundable amount: ${payment.amount_refundable_cents}`) }
            return
        }

        let advPosTransactionSplits

        switch(true) {
            case refundOption === 'full' : {
                advPosTransactionSplits = payment.adv_pos_tab_transaction_splits.map(split => {
                    delete split['total_received_cents']

                    return {
                        ...split,
                        items: (split.items || []).filter(item => !refundedUuids.includes(item.uuid)),
                        booking_items: (split.booking_items || []).filter(item => !refundedUuids.map(i => i.uuid).includes(item.uuid)),
                        total_received_booking_cents: (!isNonSplitBookingRefunded && (!split.booking_items || split.booking_items.length === 0)) ? split.total_received_booking_cents : 0,
                        booking_taxes_and_fees: (!isNonSplitBookingRefunded && (!split.booking_items || split.booking_items.length === 0)) ? split.booking_taxes_and_fees : {}
                    }
                })

                if (await (email !== "" || confirm('Refund without sending emailed receipt?'))) {
                    if (await confirm(`<p>Are you sure?</p>${selectedMethod == 'gift_card' ? '' : '<p>This cannot be undone.</p>'}`, { dangerous: selectedMethod != 'gift_card' })) {
                        processRefund(advPosTransactionSplits)
                    }
                }
                break
            }

            case refundOption === 'partial' : {
                advPosTransactionSplits = selectedTabItems

                if (await (email !== "" || confirm('Refund without sending emailed receipt?'))) {
                    if (await confirm(`<p>Are you sure?</p>${selectedMethod == 'gift_card' ? '' : '<p>This cannot be undone.</p>'}`, { dangerous: selectedMethod != 'gift_card' })) {
                        processRefund(advPosTransactionSplits)
                    }
                }
                break
            }

            case refundOption === 'custom' : {
                const generalTab = tabs.find(tab => tab.is_general)
                const creditItem = generalTab.items.find(item => /^booking_(pre_payment|deposit)/i.test(item.item.type) && item.item.id === payment.id)
                const amount     = (customAmount * 100).toFixed(0)

                advPosTransactionSplits = [{
                    tab_id: generalTab.id,
                    booking_items: [{
                        uuid: creditItem.item.uuid,
                        total_received_cents: amount,
                        name: creditItem.item.name,
                    }],
                    total_received_booking_cents: amount,
                }]

                if (await (email !== "" || confirm('Refund without sending emailed receipt?'))) {
                    if (await confirm(`<p>Is the refund amount correct?</p>${selectedMethod == 'gift_card' ? '' : '<p>This cannot be undone.</p>'}`, { dangerous: selectedMethod != 'gift_card' })) {
                        processRefund(advPosTransactionSplits)
                    }
                }
                break
            }
        }
    }

    useEffect(() => {
        setEmail(payment.email || '')
        setMethodOptions(payment.transaction_type === 'credit' ? ['credit_card', 'gift_card'] : Object.keys(payment.available_refund_methods))
        setSelectedMethod(payment.transaction_type === 'credit' ? 'credit_card' : Object.keys(payment.available_refund_methods)[0])

        if (!payment.check_id) {
            setRefundOption('custom')
            setCustomAmount(((payment.amount_refundable_cents - (isTipRefunded ? 0 : payment.tip_cents)) / 100.0).toFixed(2))
        }
    }, [])

    return <>
        {(refundOption === 'full' || refundOption === 'partial') && <>
            {payment.amount_refundable_cents !== payment.tip_cents && <>
                <div className='mt-4 mb-2'>
                    <strong>Please Choose:</strong>
                    <input
                        type='radio'
                        name='full'
                        id='full'
                        value='full'
                        checked={refundOption === 'full'}
                        className='radio-tab-input mr-1 ml-3'
                        onChange={() => setRefundOption('full')}
                    />
                    <label htmlFor='full'>Full Refund</label>
                    <input
                        type='radio'
                        name='partial'
                        id='partial'
                        value='partial'
                        checked={refundOption === 'partial'}
                        className='radio-tab-input mr-1 ml-3'
                        onChange={() => setRefundOption('partial')}
                    />
                    <label htmlFor='partial'>Partial Refund</label>
                </div>

                <hr className='mt-0 mb-3 border-thick' />

                {refundOption === 'partial' &&
                    <RefundItems
                        payment={payment}
                        tabs={tabs}
                        selectedTabItems={selectedTabItems}
                        setSelectedTabItems={setSelectedTabItems}
                        isNonSplitBookingRefunded={isNonSplitBookingRefunded}
                        refundedUuids={refundedUuids}
                    />
                }
            </>}

            {payment.tip_cents > 0 && !isTipRefunded &&
                <div className='px-3 mb-3'>
                    <input
                        type='checkbox'
                        name='tip_cents'
                        id='tip_cents'
                        value='tip_cents'
                        checked={includesTip}
                        className='mr-2'
                        onChange={() => setIncludesTip(!includesTip)}
                    />
                    <label htmlFor='tip_cents' className='mb-0'>
                        Tip Amount ({numberToCurrency(payment.tip_cents / 100.0)})
                    </label>
                </div>
            }

            {
                refundOption === 'partial' && (
                    <hr className='mt-0 mb-4 border-thick' />
                )
            }
        </>}

        <form onSubmit={handleProcessRefund}>
            <div className='row mt-4'>
                <div className='col-6'>
                    {refundOption === 'custom' && <>
                        {(payment.amount_refundable_cents - (isTipRefunded ? 0 : payment.tip_cents) > 0) &&
                            <div className="form-group">
                                <label className="req">Refund Amount</label>
                                <input
                                    className="form-control"
                                    type="number"
                                    placeholder="0.00"
                                    step=".01"
                                    min="0"
                                    max={(payment.amount_refundable_cents - (isTipRefunded ? 0 : payment.tip_cents)) / 100}
                                    name="amount"
                                    value={customAmount || ''}
                                    onChange={e => setCustomAmount(e.target.value)}
                                />
                            </div>
                        }
                        {payment.tip_cents > 0 && !isTipRefunded &&
                            <div className='mb-3'>
                                <input
                                    type='checkbox'
                                    name='tip_cents'
                                    id='tip_cents'
                                    value='tip_cents'
                                    checked={includesTip}
                                    className='mr-2'
                                    onChange={() => setIncludesTip(!includesTip)}
                                />
                                <label htmlFor='tip_cents' className='mb-0'>
                                    Tip Amount ({numberToCurrency(payment.tip_cents / 100.0)})
                                </label>
                            </div>
                        }
                    </>}
                    <div className='form-group'>
                        <label htmlFor='refund_method'>
                            Refund Method
                        </label>
                        {methodOptions.map((option, index) => (
                            <div key={index}>
                                <input
                                    type='radio'
                                    name={`refund_method_${option}`}
                                    id={`refund_method_${option}`}
                                    value={option}
                                    checked={selectedMethod === option}
                                    className='radio-tab-input mr-1 ml-3'
                                    onChange={e => setSelectedMethod(e.target.value)}
                                />
                                <label htmlFor={`refund_method_${option}`} className='pl-2'>{titleize(option)}</label>
                            </div>
                        ))}
                    </div>
                </div>

                <div className='col-6'>
                    <div className='form-group'>
                        <label className='email' htmlFor='email'>Receipt Email</label>
                        <input
                            type='email'
                            name='email'
                            id='email'
                            className='form-control'
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                        />
                    </div>

                    <div className='form-group'>
                        <label htmlFor='notes'>Notes</label>
                        <textarea
                            name='notes'
                            className='form-control'
                            value={notes}
                            onChange={e => setNotes(e.target.value)}
                        />
                    </div>
                </div>
            </div>

            <div className='border-top border-top-thick mt-4 pt-3 text-right d-flex align-items-center justify-content-between'>
                <strong>
                    <span className='pr-2'>
                        Refund Amount:
                    </span>
                    {numberToCurrency((amount / 100).toFixed(2))}
                </strong>

                <button
                    type='submit'
                    className='btn btn-primary ml-auto'
                    disabled={amount === 0 || noTabsPresent}
                    children='Process Refund'
                />
            </div>
        </form>

        {
            noTabsPresent && (
                <div className='alert alert-danger rounded py-2 w-100 mt-3 mb-0 font-weight-bold text-center'>
                    Cannot continue to process refund! Not enough data present!
                </div>
            )
        }
    </>
}
