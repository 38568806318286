import { createSlice } from "@reduxjs/toolkit"
import axios from 'axios'
import { setOpen, setStep } from "../Schedule/scheduleSlice"
import { addAlert } from '@/features/Notifications/notificationSlice'
import { setParentBooking } from "../ParentBooking/parentBookingSlice";
import { isEmpty } from "lodash";
import { selectBooking as calendarSelectBooking } from '@/features/Calendar/calendarSlice'

export const editBookingSlice = createSlice({
    name: 'editBooking',
    initialState: {
        openModal: false,
        managerCodeIsValid: null,
        booking: null,
        resources: [],
        customerTypeCounts: {},
        customerTypeCountsFetched: false,
        confirmationResent: null,
        confirmationTextResent: null,
        expandedActions: false,
        memberCount: 0,
        members: [],
        tempMembers: [],
        openServerSelectModal: false,
        openAddCardToBookingModal: false,
        openSendLinkToCustomerModal: false,
    },
    reducers: {
        setNewBooking: (state, _action) => {
            state.booking   = null
            state.resources = []
        },
        setBooking: (state, action) => {
            // whenever a booking is loading, we have to set its date and time attributes
            // since those are sent separately and used separately in the UI.
            state.booking = {
                ...action.payload.booking,
                date: action.payload.booking.start_time,
                time: action.payload.booking.start_time,
                number_of_resources: action.payload.resources.length
            }
            state.resources = action.payload.resources
        },
        resetBooking: (state, _action) => {
            state.booking   = null
            state.resources = []
            state.members = []
            state.memberCount = 0
            state.tempMembers = []
        },
        setBookingAttribute: (state, action) => {
            state.booking[action.payload.name] = action.payload.value
        },
        setBookingInUseBy: (state, action) => {
            if (state.booking !== null) {
                state.booking.drawer_open = action.payload.drawer_open
                state.booking.drawer_user = action.payload.drawer_user
            }
        },
        setCustomerTypeCounts: (state, action) => {
            state.customerTypeCounts = action.payload
        },
        setManagerCodeValidity: (state, action) => {
            state.managerCodeIsValid = action.payload
        },
        openManagerOverrideModal: (state, _action) => {
            state.openModal = true
        },
        closeManagerOverrideModal: (state, _action) => {
            state.openModal = false
        },
        setCustomerTypeCountsFetched: (state, action) => {
            state.customerTypeCountsFetched = action.payload
        },
        setConfirmationResent: (state, action) => {
            state.confirmationResent = action.payload
        },
        setConfirmationTextResent: (state, action) => {
            state.confirmationTextResent = action.payload
        },
        setExpandedActions: (state, action) => {
            state.expandedActions = action.payload
        },
        setMemberCount: (state, action) => {
            state.memberCount = action.payload
        },
        setMembers: (state, action) => {
            state.members = action.payload
        },
        setMember: (state, action) => {
            state.members[action.payload.index].memberId = action.payload.value
            state.tempMembers[action.payload.index].memberId = action.payload.value
        },
        removeMember: (state, action) => {
            const newMembers = [...state.members]
            const newTempMembers = [...state.tempMembers]

            newMembers.splice(action.payload.index, 1)
            newTempMembers.splice(action.payload.index, 1)

            state.members = newMembers
            state.tempMembers = newTempMembers
            state.memberCount --
        },
        setTempMembers: (state, action) => {
            state.tempMembers = action.payload
        },
        setOpenServerSelectModal: (state, action) => {
            state.openServerSelectModal = action.payload
        },
        setOpenAddCardBookingModal: (state, action) => {
            state.openAddCardToBookingModal = action.payload
        },
        setOpenSendLinkToCustomerModal: (state, action) => {
            state.openSendLinkToCustomerModal = action.payload
        },
    }
})

export const {
    setNewBooking,
    setBooking,
    setBookingAttribute,
    setBookingInUseBy,
    setCustomerTypeCounts,
    setManagerCodeValidity,
    openManagerOverrideModal,
    closeManagerOverrideModal,
    setCustomerTypeCountsFetched,
    setConfirmationResent,
    setConfirmationTextResent,
    setExpandedActions,
    resetBooking,
    setMemberCount,
    setMembers,
    setMember,
    setTempMembers,
    removeMember,
    setOpenServerSelectModal,
    setOpenAddCardBookingModal,
    setOpenSendLinkToCustomerModal,
} = editBookingSlice.actions

export const selectBooking                     = state => state.editBooking.booking
export const selectBookingId                   = state => state.editBooking?.booking?.id || null
export const selectBookingSource               = state => state.editBooking?.booking?.source_type || null
export const selectBookingDeposit              = state => state.editBooking?.booking?.deposit_amount || 0
export const selectBookingTaxExemptStatus      = state => state.editBooking?.booking?.is_tax_exempt || false
export const selectBookingHasAddons            = state => state.editBooking?.booking?.booking_addon_times?.length > 0
export const selectResources                   = state => state.editBooking.resources
export const selectDate                        = state => state.editBooking.booking.date
export const selectTime                        = state => state.editBooking.booking.time
export const selectStartTime                   = state => state.editBooking.booking.time
export const selectDuration                    = state => state.editBooking.booking.duration
export const selectPackageId                   = state => state.editBooking.booking.package_id
export const selectParticipants                = state => state.editBooking.booking?.participants
export const selectGroupMin                    = state => state.editBooking.booking.group_min
export const selectGroupMax                    = state => state.editBooking.booking.group_max
export const selectCustomers                   = state => state.editBooking.booking?.customers || []
export const selectPaymentsCount               = state => state.editBooking.booking?.number_of_payments || 0
export const selectNumberOfResources           = state => state.editBooking.booking.number_of_resources
export const selectOverrideResource            = state => state.editBooking.booking.override_resource
export const selectCustomerTypeCounts          = state => state.editBooking.customerTypeCounts
export const selectOpenModal                   = state => state.editBooking.openModal
export const selectManagerCodeValidity         = state => state.editBooking.managerCodeIsValid
export const selectPricingType                 = state => state.editBooking.booking?.pricing_type
export const selectConfirmationResent          = state => state.editBooking.confirmationResent
export const selectConfirmationTextResent      = state => state.editBooking.confirmationTextResent
export const selectExpandedActions             = state => state.editBooking.expandedActions
export const selectCustomerTypeCountsFetched   = state => state.editBooking.customerTypeCountsFetched
export const selectMemberCount                 = state => state.editBooking.memberCount
export const selectMembers                     = state => state.editBooking.members
export const selectTempMembers                 = state => state.editBooking.tempMembers
export const selectOpenServerSelectModal       = state => state.editBooking.openServerSelectModal
export const selectOpenAddCardToBookingModal   = state => state.editBooking.openAddCardToBookingModal
export const selectOpenSendLinkToCustomerModal = state => state.editBooking.openSendLinkToCustomerModal

export const generateInvoiceLink = (state) => state.editBooking?.booking?.invoice_url
export const generatePaymentLink = (state) => state.editBooking?.booking?.payment_url
export const generateWaiverLink  = (state) => state.editBooking?.booking?.waiver_url

export const selectReservationHolderInformation = state => ({
    name: state.editBooking.booking?.name,
    email: state.editBooking.booking?.email,
    phone: state.editBooking.booking?.phone,
})

export function updateBooking(skipClose = false, convertQuoteToBooking=false, callback=null) {
    return async (dispatch, getState) => {
        const token              = getState().session.formToken
        const booking            = getState().editBooking.booking
        const customerTypeCounts = getState().editBooking.customerTypeCounts
        const members            = getState().editBooking.members

        if (!token || !booking) {
            return dispatch(addAlert({ type: 'error', text: 'Could not update, dependencies not met!' }))
        }

        let updatedBooking = {
            ...booking,
            customer_type_counts: customerTypeCounts
        }

        if (convertQuoteToBooking === true) {
            updatedBooking.is_quote = false
        }

        if (isEmpty(members)) {
            updateBookingRequest(dispatch, token, booking, updatedBooking, members, skipClose, callback)
        } else {
            const locationId = getState().calendar.locationId
            const date = booking.start_time
            const time = booking.start_time
            const duration = booking.duration

            axios.post(`/locations/${locationId}/members/check_membership_ids`, {
                members,
                duration,
                date,
                time
            }).then(({ data }) => {
                const memberErrors = !isEmpty(data.members.filter(m => !isEmpty(m.errors)))

                dispatch(setMembers(data.members))

                if (!memberErrors) {
                    updateBookingRequest(dispatch, token, booking, updatedBooking, members, skipClose, callback)
                }
            })
        }
    }
}

function updateBookingRequest(dispatch, token, booking, updatedBooking, members, skipClose, callback) {
    axios.patch(`/bookings/${booking.id}`, {
        authenticity_token: token,
        booking: updatedBooking,
        members: members
    }).then(async ({ data }) => {
        if (data.success) {
            dispatch(setBooking({
                booking: data.booking,
                resources: data.booking.resources
            }))
        }

        if (data.success && !skipClose) {
            dispatch(setOpen(false))
        }

        dispatch(addAlert({ type: 'success', text: data.message }))

        if (data.has_start_date_or_time_changed) {
            if (!isBookingQuote(data.booking) && await confirm('Do you want to let the customer know about the change?')) {
                axios.get(`/bookings/${data.booking.id}/notify_changed`)
            }

            dispatch(calendarSelectBooking(data.booking))
        }

        if (callback && typeof callback === 'function') {
            callback()
        }

        return data
    }).catch((e) => {
        if (console) { console.error(e) }
    })
}

export function setBookingTaxExemptStatus(status) {
    return async (dispatch, getState) => {
        const token              = getState().session.formToken
        const booking            = getState().editBooking.booking
        const customerTypeCounts = getState().editBooking.customerTypeCounts

        axios.patch(`/bookings/${booking.id}/update_tax_exempt_status`, {
            authenticity_token: token,
            booking: {
                is_tax_exempt: status,
                customer_type_counts: customerTypeCounts
            }
        }).then(({ data }) => {
            if (data.success) {
                dispatch(addAlert({ type: 'success', text: data.message }))
                return
            }

            dispatch(addAlert({ type: 'error', text: data.message }))
        }).catch(e => {
            if (console) { console.warn(e) }
        })
    }
}

export function fetchCustomerTypeCounts() {
    return async (dispatch, getState) => {
        const bookingId = getState().editBooking.booking.id

        dispatch(setCustomerTypeCountsFetched(false))

        axios.get(`/bookings/${bookingId}/customer_type_counts`).then(({ data }) => {
            const counts = {}

            data.forEach(bookingCustomer => {
                counts[bookingCustomer.customer_type_id] = bookingCustomer.count
            })

            dispatch(setCustomerTypeCounts(counts))
        }).catch(e => {
            if (console) { console.warn(e) }
        }).finally(() => {
            dispatch(setCustomerTypeCountsFetched(true))
        })
    }
}

export function validateManagerCode(code) {
    return async (dispatch, getState) => {
        const locationId = getState().location.location.id
        const token      = getState().session.formToken

        axios.post(`/locations/${locationId}/validate_manager_code`, {
          authenticity_token: token,
          code
        })
        .then(({ data }) => {
            dispatch(setManagerCodeValidity(data.answer))
        }).catch(e => {
            if (console) { console.warn(e) }
        })
    }
}

export function updateStatus(status, should_activate) {
    return async (dispatch, getState) => {
        const bookingId = getState().editBooking.booking.id
        const token     = getState().session.formToken

        axios.patch(`/bookings/${bookingId}/set_status`, {
            authenticity_token: token,
            should_activate,
            status,
        }).then(({ data }) => {
            if (data.success) {
                dispatch(setBooking(data.booking))

                if (data.booking.booking.is_parent) {
                    dispatch(setParentBooking(data.booking))
                }
            } else {
                dispatch(addAlert({ type: 'error', text: data.message }))
            }
        }).catch(e => {
            if (console) { console.warn(e) }
            dispatch(addAlert({ type: 'error', text: e.response.data.message }))
        })
    }
}

export function startTraining() {
    return async (dispatch, getState) => {
        const bookingId = getState().editBooking.booking.id
        const token     = getState().session.formToken

        axios.patch(`/bookings/${bookingId}/start_training`, {
            authenticity_token: token
        }).then(({ data }) => {
            if (data.success) {
                dispatch(setBooking(data.booking))

                if (data.booking.booking.is_parent) {
                    dispatch(setParentBooking(data.booking))
                }
            } else {
                dispatch(addAlert({ type: 'error', text: data.message }))
            }
        }).catch(e => {
            if (console) { console.warn(e) }
        })
    }
}

export function completeTraining() {
    return async (dispatch, getState) => {
        const bookingId = getState().editBooking.booking.id
        const token     = getState().session.formToken

        axios.patch(`/bookings/${bookingId}/complete_training`, {
            authenticity_token: token
        }).then(({ data }) => {
            if (data.success) {
                dispatch(setBooking(data.booking))
            } else {
                dispatch(addAlert({ type: 'error', text: data.message }))
            }
        }).catch(e => {
            if (console) { console.warn(e) }
        })
    }
}

// export function updateSelectedBooking(bundle) {
//     return async (dispatch, getState) => {
//         if (!getState().editBooking.booking) {
//             return
//         }
//
//         const bookingId = getState().editBooking.booking.id
//
//         if (bundle.booking.id === bookingId) {
//             dispatch(setBooking(bundle))
//         }
//     }
// }

export function resendBookingConfirmationEmail() {
    return async (dispatch, getState) => {
        const bookingId = getState().editBooking.booking.id
        const token     = getState().session.formToken

        if (!bookingId && !token) { return }

        axios.post(`/bookings/${bookingId}/resend_confirmation_email`, {
            authenticity_token: token
        }).then(({ data }) => {
            if (data.success) {
                dispatch(setConfirmationResent(true))
            } else {
                dispatch(setConfirmationResent(false))
            }
            window.setTimeout(() => dispatch(setConfirmationResent(null)), 3000)
        }).catch(e => {
            if (console) { console.warn(e) }
            dispatch(setConfirmationResent(false))
        })
    }
}

export function resendBookingConfirmationText() {
    return async (dispatch, getState) => {
        const bookingId = getState().editBooking.booking.id
        const token     = getState().session.formToken

        if (!bookingId && !token) { return }

        axios.post(`/bookings/${bookingId}/resend_confirmation_text`, {
            authenticity_token: token
        }).then(({ data }) => {
            if (data.success) {
                dispatch(setConfirmationTextResent(true))
            } else {
                dispatch(setConfirmationTextResent(false))
            }
            window.setTimeout(() => dispatch(setConfirmationTextResent(null)), 3000)
        }).catch(e => {
            if (console) { console.warn(e) }
            dispatch(setConfirmationTextResent(false))
        })
    }
}

export function sendBookingCancellationConfirmationEmail(booking_id=nil) {
    return async (dispatch, getState) => {
        const bookingId = booking_id || getState().editBooking.booking.id

        if (!bookingId) { return }

        axios.get(`/bookings/${bookingId}/notify_cancellation`)
        .then(({ data }) => {
            if (data.success) {
                dispatch(addAlert({ type: 'success', text: data.message }))
            }
        })
        .catch(e => {
            if (console) { console.warn(e) }
            dispatch(addAlert({ type: 'error', text: e.response.data.message }))
        })
    }
}

export function flagBookingDrawerOpen(booking_id, flag) {
    return async (dispatch, getState) => {
        //const token = getState().session.formToken
        //
        //axios.patch(`/bookings/${booking_id}/flag_drawer_open`, {
        //    authenticity_token: token,
        //    flag: flag
        //}).catch(e => {
        //    if (console) { console.log('Could not flag booking drawer as open: ', e) }
        //})
    }
}

// this is needed because some components (Schedule Drawer) that bind events to the ESC key, when
// they bind the event listeners will not get updated selector data when trying to select the booking
// id that needs to be passed, it's easy to just create a wrapper to get it here.
export function flagCurrentBookingDrawerOpen(flag) {
    return async (dispatch, getState) => {
        const bookingId = getState().editBooking.booking?.id
        if (bookingId) {
            dispatch(flagBookingDrawerOpen(bookingId, flag))
        }
    }
}

export function addCardToBooking(bookingId=null, profile=null) {
    return async (dispatch, getState) => {
        const token = getState().session.formToken

        if (!token || !bookingId) {
            return dispatch(addAlert({ type: 'error', text: 'Could not update, dependencies not met!' }))
        }

        if (!profile) {
            dispatch(addAlert({ type: 'error', text: 'A card profile was not passed in!' }))
            return
        }

        return axios.patch(`/bookings/${bookingId}/add_payment_card`, {
            authenticity_token: token,
            terminal_response: profile,
        })
    }
}

export default editBookingSlice.reducer
