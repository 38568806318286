import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectBooking } from '../EditBooking/editBookingSlice'
import { selectPayments, fetchBookingPayments } from './bookingHistorySlice'
import PaymentRow from './PaymentRow'
import { debug } from '@/lib/Debug'

export default function BookingHistoryPayments({
    id='payment-history',
    className='table table-middle table-sm mb-0',
    autoRefreshDelay=3000,
    preventAutoRefresh=false,
    preventRefunds=false,
    preventEmailingReceipts=false,
    userIsSuper=false,
}) {

    const dispatch = useDispatch()
    const booking  = useSelector(selectBooking)
    const payments = useSelector(selectPayments)

    const startTimer = () => {
        if (preventAutoRefresh) { return }

        stopTimer()

        window._BPH_TIMER = window.setInterval(() => {
            if (!window._BPH_TIMER_PAUSE) {
                dispatch(fetchBookingPayments(booking.id))
            }
        }, Number(autoRefreshDelay))
    }

    const stopTimer = () => {
        if (preventAutoRefresh) { return }
        window.clearInterval(window._BPH_TIMER)
        delete window._BPH_TIMER
    }

    const handleRefundRowToggle = (open) => {
        if (open) {
            stopTimer()
        } else {
            startTimer()
        }
    }

    useEffect(() => {
        stopTimer()

        if (!booking.id) { return }

        if (payments.length > 0) {
            startTimer()
        } else {
            dispatch(fetchBookingPayments(booking.id)).then(() => startTimer())
        }
    }, [booking])

    useEffect(() => {
        return () => {
            stopTimer()
        }
    }, [])

    return (
        <table id={id} className={className}>
            <thead>
                <tr>
                    { debug && <th className="border-bottom text-gray3 text-left px-1">ID</th> }
                    <th className="border-bottom text-gray3 px-1"><strong>Date &amp; Time</strong></th>
                    <th className="border-bottom text-gray3 px-1"><strong>Customer Name</strong></th>
                    <th className="border-bottom text-gray3 px-1"><strong>Type</strong></th>
                    <th className="border-bottom text-gray3 px-1"><strong>Amount</strong></th>
                    {
                        !!booking.check && (
                            <th className="border-bottom text-gray3 px-1"><strong>Refundable To<br />Reservation</strong></th>
                        )
                    }
                    <th className="border-bottom" />
                    <th className="border-bottom text-gray3 px-1"><strong>Status</strong></th>
                    <th className="border-bottom px-1" />
                    <th className="border-bottom px-1" />
                    <th className="border-bottom px-1" />
                </tr>
            </thead>
            <tbody id="payment-rows">
                {
                    payments.map((payment, index) => (
                        <PaymentRow
                            key={payment.id}
                            index={index}
                            parentId={id}
                            payment={payment}
                            userIsSuper={userIsSuper}
                            preventRefunds={preventRefunds}
                            preventEmailingReceipts={preventEmailingReceipts}
                            onRefundRowToggle={handleRefundRowToggle}
                        />
                    ))
                }

                {
                    payments.length === 0 && (
                        <tr>
                            <td
                                className='pt-4 text-muted text-center font-weight-bolder'
                                children='None Found'
                                colSpan={debug ? 10 : 9}
                            />
                        </tr>
                    )
                }
            </tbody>
        </table>
    )
}
