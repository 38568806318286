export const adyenEnv  = window.localStorage.getItem('adyen-environment')
export const isAdyenProd = adyenEnv === 'live'
export const isAdyenTest = adyenEnv === 'test'

//
// https://docs.adyen.com/risk-management/understanding-disputes/dispute-reason-codes/?tab=chargeback_1
//
export const cardTestScenarios = [
    { amount: 1.21, refusalReason: '214 Declined online',                 message: 'CANCELLED' },
    { amount: 1.22, refusalReason: '124 Acquirer fraud',                  message: 'ACQUIRER_FRAUD' },
    { amount: 1.23, refusalReason: '214 Declined online',                 message: 'DECLINED' },
    { amount: 1.24, refusalReason: '210 Not enough balance',              message: 'NOT_ENOUGH_BALANCE' },
    { amount: 1.25, refusalReason: '199 Card blocked',                    message: 'BLOCK_CARD' },
    { amount: 1.26, refusalReason: '228 Card expired',                    message: 'CARD_EXPIRED' },
    { amount: 1.27, refusalReason: '214 Declined online',                 message: 'INVALID_AMOUNT' },
    { amount: 1.28, refusalReason: '214 Declined online',                 message: 'INVALID_CARD' },
    { amount: 1.29, refusalReason: '214 Declined online',                 message: 'NOT_SUPPORTED' },
    { amount: 1.30, refusalReason: '214 Declined online',                 message: 'CONVERTER_ERROR_REQUEST' },
    { amount: 1.31, refusalReason: '214 Declined online',                 message: 'DECLINED' },
    { amount: 1.32, refusalReason: '214 Declined online',                 message: 'DECLINED' },
    { amount: 1.33, refusalReason: '214 Declined online',                 message: 'REFERRAL' },
    { amount: 1.34, refusalReason: '129 Invalid online PIN',              message: 'INVALID_PIN - The terminal shows "Incorrect PIN" and then "Enter PIN". Cancel the payment on the terminal to get the failure response.' },
    { amount: 1.35, refusalReason: '128 Online PIN tries exeeded',        message: 'PIN_TRIES_EXCEEDED' },
    { amount: 1.36, refusalReason: '207 Issuer unavailable',              message: 'ISSUER_UNAVAILABLE' },
    { amount: 1.37, refusalReason: '211 Withdrawal amount exceeded',      message: 'WITHDRAWAL_AMOUNT_EXCEEDED' },
    { amount: 1.38, refusalReason: '212 Withdrawal count exceeded',       message: 'WITHDRAWAL_COUNT_EXCEEDED' },
    { amount: 1.39, refusalReason: '210 Not enough balance',              message: 'NOT_ENOUGH_BALANCE' },
    { amount: 1.42, refusalReason: '',                                    message: 'Tap the card. The terminal shows Contactless limit exceeded, insert card.' },
    { amount: 1.43, refusalReason: '',                                    message: 'Tap the card. The terminal shows Contactless limit exceeded, insert card.' },
    { amount: 1.44, refusalReason: '214 Declined online',                 message: 'NOT_SUBMITTED' },
    { amount: 1.45, refusalReason: '214 Declined online',                 message: 'DECLINED / FRAUD_CANCELLED' },
    { amount: 1.46, refusalReason: '214 Declined online',                 message: 'TRANSACTION_NOT_PERMITTED' },
    { amount: 1.47, refusalReason: '214 Declined online',                 message: 'CVC_DECLINED' },
    { amount: 1.48, refusalReason: '214 Declined online',                 message: 'RESTRICTED_CARD' },
    { amount: 1.49, refusalReason: '214 Declined online',                 message: 'DECLINED / REVOCATION_OF_AUTH' },
    { amount: 1.50, refusalReason: '214 Declined online',                 message: 'DECLINED / DECLINED_NON_GENERIC' },
    { amount: 1.51, refusalReason: '214 Declined online',                 message: 'ISSUER_SUSPECTED_FRAUD' },
    { amount: 1.52, refusalReason: '214 Declined online',                 message: 'NO_CHECKING_ACCOUNT' },
    { amount: 1.53, refusalReason: '214 Declined online',                 message: 'NO_SAVINGS_ACCOUNT' },
    { amount: 1.54, refusalReason: '219 Shopper cancelled ctls fallback', message: 'Tap the card. The payment falls back to a non-contactless payment. The terminal shows "Present card" again, but this time without the contactless icon. Cancel the payment on the terminal to get the failure response.' },
    { amount: 1.57, refusalReason: '212 Withdrawal count exceeded',       message: 'AUTHENTICATION_REQUIRED' },
    { amount: 1.58, refusalReason: '235 AID banned',                      message: 'BAN_CURRENT_AID - In a live environment, this is the response when the application is in the penalty box, offline processing is not possible, and there are no other applications on the card.' },
    { amount: 1.59, refusalReason: '214 Declined online',                 message: 'ONLINE_PIN_REQUIRED' },
    { amount: 1.62, refusalReason: '214 Declined online',                 message: 'SECURITY_VIOLATION' },
]

export const chargeBackTestScenarios = {
    schemes: [
        { value: 'generic', text: 'GENERIC' },
        { value: 'amex', text: 'American Express'} ,
        { value: 'diners_club', text: 'Diners Club'} ,
        { value: 'discover', text: 'Discover'} ,
        { value: 'mastercard', text: 'MasterCard'} ,
        { value: 'visa', text: 'Visa'} ,
    ],
    scenarios: {
        generic: {
            generic: [
                { reason_code: 'Chargeback', reason: '', description: 'A chargeback without an explicit reason code', defendable: null, timeframe: null },
                { reason_code: 'ChargebackReversed', reason: '', description: 'A ChargebackReversed status without an explicit reason code', defendable: null, timeframe: null },
                { reason_code: 'SecondChargeback', reason: '', description: 'A SecondChargeback status without an explicit reason code', defendable: null, timeframe: null }
            ]
        },
        amex: {
            authorization: [
                { reason_code: 4521, reason_code_us: 'A01', reason: 'Incorrect Transaction Amount Presented - Charge Amount Exceeds Authorization Amount', description: 'The charges submitted were either incorrect or exceeded the authorized amount.', defendable: true, timeframe: '14 days from receiving the NoC' },
                { reason_code: 4521, reason_code_us: 'A02', reason: 'No Valid Authorization', description: 'The payment could not be authorized.', defendable: true, timeframe: '14 days from receiving the NoC' },
                { reason_code: 4755, reason_code_us: 'A08', reason: 'Authorization Approval Expired - No valid Authorization', description: 'The payment could not be authorized because the transaction expired.', defendable: true, timeframe: '14 days from receiving the NoC' },
            ],
            consumer_disputes: [
                { reason_code: 4513, reason_code_us: 'C02', reason: 'No Credit Received/Credit not Processed', description: "A credit has not been applied to the cardholder's account for either of the following reasons: Goods/services canceled, You have made an advance deposit or payment, A no show reservation.", defendable: true, timeframe: '14 days from receiving NoC' },
                { reason_code: 4513, reason_code_us: 'C04', reason: 'Goods and Services not Received/Refused', description: 'The cardholder claims that the goods or services they purchased at your business were not received or refused.', defendable: true, timeframe: '14 days from receiving NoC' },
                { reason_code: 4513, reason_code_us: 'C05', reason: 'Goods Returned/Canceled', description: 'The cardholder claims that the goods/services they ordered were canceled.', defendable: true, timeframe: '14 days from receiving NoC' },
                { reason_code: 4513, reason_code_us: 'C18', reason: 'No Show or Canceled CARDeposit (card deposit) / Lodging reservation canceled or a credit for a CARDeposit charge was not received', description: 'The cardholder claims that they have canceled a lodging reservation or they did not receive a credit for a CARDeposit transaction.', defendable: true, timeframe: '14 days from receiving NoC' },
                { reason_code: 4515, reason_code_us: 'C14', reason: 'Paid through Other Means', description: 'The cardholder provided proof of payment by another method.', defendable: true, timeframe: '14 days from receiving NoC' },
                { reason_code: 4532, reason_code_us: 'C32', reason: 'Damaged and/or Defective Goods/Services', description: 'The cardholder disputes the quality of the merchandise or services.', defendable: true, timeframe: '14 days from receiving NoC' },
                { reason_code: 4544, reason_code_us: 'C28', reason: 'Cancellation of Recurring Goods/Services', description: 'The cardholder claims that their account continues to be billed for recurring goods or services they have previously canceled or revoked.', defendable: true, timeframe: '14 days from receiving NoC' },
                { reason_code: 4553, reason_code_us: 'C31', reason: 'Not as Described', description: 'The goods or services received from your business were either not as described by your business or the price should be lower than that claimed by your business.', defendable: true, timeframe: '14 days from receiving NoC' },
                { reason_code: 4554, reason_code_us: 'C08', reason: 'Goods/Services Not Received or Only Partially Received.', description: 'The cardholder claims that they did not receive the goods/services or only partially.', defendable: true, timeframe: '14 days from receiving NoC' },
                { reason_code: 4750, reason_code_us: 'M10', reason: 'Vehicle Rental - Damages, Theft, or loss of Use/ Car Rental Charge in Dispute', description: 'The cardholder claims that they have been incorrectly billed for damages, theft, or loss of use.', defendable: true, timeframe: '14 days from receiving NoC' },
            ],
            fraud: [
                { reason_code: 4527, reason_code_us: 'F10', reason: 'Missing Imprint', description: 'The cardholder denies participation in a charge that was not processed using Magnetic Stripe or Chip Card Data.', defendable: true, timeframe: '14 days from receiving NoC' },
                { reason_code: 4534, reason_code_us: 'F24', reason: 'No Card Member Authorization/Multiple Records of Charge', description: 'The cardholder denies participation in the charge submitted, and you have failed to provide proof that the cardholder did participate in the transaction.', defendable: true, timeframe: '14 days from receiving NoC' },
                { reason_code: 4540, reason_code_us: 'F29', reason: 'Card Not Present (fraud)', description: 'The cardholder denies participation in a card-absent environment such as Mail Order/Telephone Order (MOTO) or online transaction.', defendable: true, timeframe: '14 days from receiving NoC' },
                { reason_code: 4763, reason_code_us: 'FR2', reason: 'Full recourse', description: 'The cardholder challenged the charge, and you have been placed in the Fraud Full Recourse Program.', defendable: false, timeframe: null },
                { reason_code: 4798, reason_code_us: 'F30', reason: 'Fraud Liability Shift – Counterfeit', description: 'The cardholder claims that they did not authorize or participate in a transaction that you processed.', defendable: true, timeframe: '14 days from receiving NoC' },
                { reason_code: 4799, reason_code_us: 'F31', reason: 'Fraud Liability Shift – Lost/Stolen/Non-Received', description: 'The cardholder claims that they did not authorize or participate in a transaction that you processed.', defendable: true, timeframe: '14 days from receiving NoC' },
                { reason_code: null, reason_code_us: 'FR4', reason: 'Placed in Immediate Chargeback Program', description: 'The cardholder challenged the charge, and you have been placed in the Immediate Chargeback Program.', defendable: false, timeframe: null },
                { reason_code: null, reason_code_us: 'FR6', reason: 'Placed in the Partial Immediate Chargeback Program', description: 'The cardholder challenged the charge, and you have been placed in the Partial Immediate Chargeback Program.', defendable: false, timeframe: null },
            ],
            processing_errors: [
                { reason_code: 4507, reason_code_us: 'P05', reason: 'Incorrect Transaction Amount Presented', description: 'Charges were incorrectly submitted by using an incorrect amount.', defendable: true, timeframe: '14 days from receiving NoC' },
                { reason_code: null, reason_code_us: 'P22', reason: 'Non-matching Card Number', description: 'The card number used for the transaction is not assigned to a valid account nor to the cardholder.', defendable: true, timeframe: '14 days from receiving NoC' },
                { reason_code: 4512, reason_code_us: 'P08', reason: 'Multiple Processing/Duplicate Charge', description: "A charge was incorrectly submitted more than once to the cardholder's account.", defendable: true, timeframe: '14 days from receiving NoC' },
                { reason_code: 4523, reason_code_us: 'P01', reason: 'Invalid/Incorrect Card Number - Unassigned Card Number', description: 'The card number used for the transaction is not assigned to a valid account or to the cardholder.', defendable: true, timeframe: '14 days from receiving NoC' },
                { reason_code: 4530, reason_code_us: 'P23', reason: 'Currency Discrepancy', description: 'The cardholder was advised that the charge is in a currency that differs from what they originally agreed upon.', defendable: true, timeframe: '14 days from receiving NoC' },
                { reason_code: 4536, reason_code_us: 'P07', reason: 'Late presentment/Late submission', description: 'The transaction was not captured within the timeframe.', defendable: false, timeframe: null },
                { reason_code: 4752, reason_code_us: 'P03', reason: 'Credit / Debit Presentment Error', description: 'The cardholder was debited for a transaction that should have been a credit.', defendable: true, timeframe: '14 days from receiving NoC' },
                { reason_code: 4752, reason_code_us: 'P04', reason: 'Credit / Debit Presentment Error', description: 'The cardholder was debited for a transaction that should have been a debit.', defendable: true, timeframe: '14 days from receiving NoC' },
                { reason_code: 4558, reason_code_us: 'F22', reason: 'Expired/Not Yet valid card', description: 'The transaction completed with an expired card.', defendable: false, timeframe: null },
            ],
            retrieval_chargeback: [
                { reason_code: 4516, reason_code_us: 'R13', reason: 'No Reply / No Response for Inquiry Provided', description: 'American Express did not receive a response to a previous inquiry within the specified timeframe.', defendable: true, timeframe: '14 days from receiving NoC' },
                { reason_code: 4517, reason_code_us: null,  reason: 'Request for Support Illegible/Incomplete', description: 'The documents received from you were insufficient and/or did not link the transaction to the cardholder.', defendable: true, timeframe: '14 days from receiving NoC' },
                { reason_code: 4571, reason_code_us: 'R03', reason: 'Complete support and/or Documentation not Provided/ Insufficient Reply', description: 'Documentation is missing and not provided as requested.', defendable: true, timeframe: '14 days from receiving NoC' },
                { reason_code: 4754, reason_code_us: 'M01', reason: 'Authorization to process Chargeback Received/ Chargeback Authorization', description: 'American Express has received authorization to process the chargeback.', defendable: true, timeframe: '14 days from receiving NoC' },
            ],
        },
        diners_club: {
            authorization: [
                { reason_code: 'A02', reason: 'Authorization Processing Errors', description: 'The payment could not be authorized because of errors.', defendable: false, timeframe: null },
                { reason_code: 'A06', reason: 'Unissued Account Number', description: 'The account number does not correspond to the account numbers the issuer has on file for this cardholder.', defendable: false, timeframe: null },
            ],
            consumer_disputes: [
                { reason_code: 'D62', reason: 'Non-Receipt of Goods or Services', description: 'The cardholder claims that they did not receive the goods or services they purchased at your business.', defendable: true, timeframe: '25 days from receiving the NoC' },
                { reason_code: 'D66', reason: 'Credit not Processed', description: "A credit has not been applied to the cardholder's account for either of the following reasons: Goods/services canceled, You have made an advance deposit or payment, A no show reservation.", defendable: true, timeframe: '25 days from receiving the NoC' },
                { reason_code: 'D69', reason: 'Canceled Recurring Transactions', description: 'The cardholder claims that their account continues to be billed for recurring goods or services they have previously canceled or revoked.', defendable: true, timeframe: '25 days from receiving the NoC' },
                { reason_code: 'D70', reason: 'Card member Does Not Recognize', description: 'The cardholder does not recognize the transaction and claims that they did not authorize the charge to their credit card.', defendable: true, timeframe: '25 days from receiving the NoC' },
            ],
            fraud: [
                { reason_code: 'C41', reason: 'Fraud - Card Present Transaction', description: 'The cardholder did not authorize or participate in a card-present transaction.', defendable: true, timeframe: '25 days from receiving the NoC' },
                { reason_code: 'C42', reason: 'Fraud - Card Not Present Transaction', description: 'The cardholder did not authorize or participate in a transaction conducted in a card-absent environment such as online, Mail Order/Telephone Order (MOTO), or other options.', defendable: true, timeframe: '25 days from receiving the NoC' },
                { reason_code: 'C46', reason: 'Multiple Charges at Service Establishment Fraudulent Transaction', description: 'The cardholder acknowledges participating in at least one transaction but denies participating in one or more other charges at the same service establishment.', defendable: false, timeframe: null },
                { reason_code: 'C53', reason: 'Fraud – Chip Card Counterfeit Transaction', description: 'The cardholder claims that they did not authorize or participate in a transaction that you processed.', defendable: false, timeframe: null },
                { reason_code: 'C54', reason: 'Fraud – Lost or Stolen Chip and PIN Card Transaction', description: 'The cardholder did not authorize or participate in a card-present transaction', defendable: false, timeframe: null },
            ],
            processing_errors: [
                { reason_code: 'B24', reason: 'Late Presentation', description: 'The transaction was not sent to Diners within the timeframe.', defendable: false, timeframe: null },
                { reason_code: 'B25', reason: 'Duplicate Charge', description: 'The cardholder claims that a single transaction was processed more than once.', defendable: true, timeframe: '25 days from receiving the NoC' },
                { reason_code: 'B26', reason: 'Alternate Settlement Currency Incorrect Exchange Rates', description: 'The cardholder claims that the converted amount of charge on an international transaction is incorrect.', defendable: true, timeframe: '25 days from receiving the NoC' },
                { reason_code: 'B27', reason: 'Incorrect Currency', description: 'You sent a transaction that was processed with an incorrect currency code. The cardholder was not advised or did not agree that Dynamic Currency Conversion (DCC) would occur.', defendable: true, timeframe: '25 days from receiving the NoC' },
                { reason_code: 'D61', reason: 'Altered Amount', description: 'The cardholder claims that the amount they agreed to pay differs from the amount charged.', defendable: true, timeframe: '25 days from receiving the NoC' },
                { reason_code: 'D67', reason: 'Cardmember Paid by Other Means', description: 'The cardholder claims that they paid for the merchandise or service by other means such as cash, check, another card, or other options.', defendable: true, timeframe: '25 days from receiving the NoC' },
            ],
        },
        discover: {
            authorization: [
                { reason_code: 4753, reason: 'Invalid Cardholder Number', description: 'The account number does not correspond to the account numbers the issuer has on file for this cardholder.', defendable: false, timeframe: null },
            ],
            consumer_disputes: [
                { reason_code: 4541, reason: 'Recurring Payment', description: 'The cardholder claims that their account continues to be billed for recurring goods or services they have canceled or revoked.', defendable: true, timeframe: '25 days from receiving the NoC' },
                { reason_code: 4553, reason: 'Cardholder Disputes Quality of Goods or Services', description: 'The cardholder is not happy with the quality of the merchandise or service.', defendable: true, timeframe: '25 days from receiving the NoC' },
                { reason_code: 4755, reason: 'Non-Receipt of Goods or Services ', description: 'The cardholder claims that they did not receive the goods or services they purchased at your business.', defendable: true, timeframe: '25 days from receiving the NoC' },
                { reason_code: 8002, reason: 'Credit Not Processed ', description: "A credit has not been applied to the cardholder's account for either of the following reasons: Goods/services canceled, You have made an advance deposit or payment, A no show reservation.", defendable: true, timeframe: '25 days from receiving the NoC' },
            ],
            fraud: [
                { reason_code: 4752, reason: 'Does not Recognize', description: 'The cardholder claims that they do not recognize the transaction and states that they did not authorize the charge to their credit card.', defendable: true, timeframe: '25 days from receiving the NoC' },
                { reason_code: 4866, reason: 'Fraud Chip Card Counterfeit Transaction', description: 'The cardholder claims that they did not authorize or participate in a transaction that you processed.', defendable: false, timeframe: null },
                { reason_code: 4867, reason: 'Fraud Chip Card and PIN Transaction', description: 'The cardholder claims that they did not authorize or participate in a transaction that you processed.', defendable: false, timeframe: null },
                { reason_code: 7010, reason: 'Fraud Card Present Transaction', description: 'The cardholder claims that they did not authorize or participate in a key-entered or unattended transaction conducted in a card-present environment.', defendable: true, timeframe: '25 days from receiving the NoC' },
                { reason_code: 7030, reason: 'Fraud Card Not Present Transaction', description: 'The cardholder claims that they did not authorize or participate in a transaction conducted in a card-absent environment such as online, Mail Order/Telephone Order (MOTO), or other options.', defendable: true, timeframe: '25 days from receiving the NoC' },
            ],
            processing_errors: [
                { reason_code: 4534, reason: 'Duplicate Processing', description: 'The cardholder claims that a single transaction was processed more than once.', defendable: true, timeframe: '25 days from receiving the NoC' },
                { reason_code: 4542, reason: 'Late Presentation', description: 'The transaction was not sent to Discover within the timeframe.', defendable: false, timeframe: null },
                { reason_code: 4550, reason: 'Credit/ Debit Posted Incorrectly ', description: 'An account is posted as debit in place of a credit by mistake.', defendable: true, timeframe: '25 days from receiving the NoC' },
                { reason_code: 4586, reason: 'Altered Amount', description: 'The cardholder claims that the amount they agreed to pay differs from the amount charged.', defendable: true, timeframe: '25 days from receiving the NoC' },
                { reason_code: 4865, reason: 'Paid by Other Means', description: 'The cardholder claims that they paid for the merchandise or service by other means such as cash, check, another card, or other options.', defendable: true, timeframe: '25 days from receiving the NoC' },
            ],
        },
        mastercard: {
            authorization: [
                { reason_code: 4802, reason: 'Requested Authorization not obtained', description: 'The payment could not be authorized.', defendable: false, timeframe: null },
                { reason_code: 4808, reason: 'Required Authorization not obtained', description: 'The payment could not be authorized.', defendable: false, timeframe: null },
                { reason_code: 4807, reason: 'Warning Bulletin File', description: 'The payment could not be authorized.', defendable: false, timeframe: null },
                { reason_code: 4812, reason: 'Account Number Not on File', description: 'The account number does not correspond to the account numbers the issuer has on file for this cardholder.', defendable: false, timeframe: null },
                { reason_code: 4835, reason: 'Card Not Valid or Expired', description: 'A transaction completed with an expired card.', defendable: false, timeframe: null },
            ],
            consumer_disputes: [
                { reason_code: 4853, reason: 'Cardholder Dispute', description: 'Following the Mastercard dispute guidelines, consumer dispute chargebacks are filed under reason code 4853.', defendable: true, timeframe: '40 days from receiving the NoC' },
            ],
            fraud: [
                { reason_code: 4837, reason: 'No Cardholder Authorization', description: 'The cardholder states that they, nor anyone authorized by them, engaged in the transaction.', defendable: true, timeframe: '40 days from receiving the NoC' },
                { reason_code: 4840, reason: 'Fraudulent Processing of Transactions', description: "The cardholder claims that a fraudulent purchase was made while the card was in the cardholder's possession at the time of the transaction.", defendable: false, timeframe: null },
                { reason_code: 4849, reason: 'Questionable merchant activity', description: 'The acquirer processed a transaction that was later listed in a Mastercard Global Security Bulletin for violating GMAP, QMAP, or reported to SAFE.', defendable: false, timeframe: null },
                { reason_code: 4863, reason: 'Cardholder does not recognize – Potential Fraud', description: 'The cardholder claims that they do not recognize the transaction and did not authorize the charge to their credit card.', defendable: false, timeframe: null },
                { reason_code: 4870, reason: 'Chip Liability Shift', description: 'The cardholder claims that they were in possession of a valid card on the date of transaction, but they did not authorize or participate in the transaction.', defendable: false, timeframe: null },
                { reason_code: 4871, reason: 'Chip/PIN Liability Shift', description: 'The cardholder claims that they were not in possession of a valid card on the date of transaction, and they did not authorize or participate in the transaction.', defendable: false, timeframe: null },
                { reason_code: 4999, reason: 'Domestic Chargeback Dispute (Europe region only)', description: "The issuer can supply this message for a domestic chargeback that doesn't meet another chargeback categorization.", defendable: true, timeframe: '40 days from receiving the NoC' },
            ],
            processing_errors: [
                { reason_code: 4834, reason: 'Point of Interaction error', description: 'Following the Mastercard dispute guidelines, all processing error chargebacks are filed under reason code 4834.', defendable: true, timeframe: '40 days from receiving the NoC' },
            ],
        },
        visa: {
            authorization: [
                { reason_code: '11.1', reason: 'Card Recovery Bulletin', description: 'The transaction was below your floor limit and was not authorized.', defendable: false, timeframe: null },
                { reason_code: '11.2', reason: 'Declined Authorization', description: 'An Authorization Request was declined.', defendable: false, timeframe: null },
                { reason_code: '11.3', reason: 'No Authorization', description: 'The payment could not be authorized.', defendable: false, timeframe: null },
            ],
            consumer_disputes: [
                { reason_code: '13.1', reason: 'Merchandise/Services Not Received', description: 'The cardholder did not receive the merchandise/services because you were unwilling or unable to provide these.', defendable: true,  timeframe: '18 days from receiving the NoC' },
                { reason_code: '13.2', reason: 'Canceled Recurring', description: "A recurring transaction was processed after it was canceled or that the cardholder's account was closed.", defendable: true,  timeframe: '18 days from receiving the NoC' },
                { reason_code: '13.3', reason: 'Not as Described or Defective Merchandise/Services', description: 'The cardholder claims that either the goods were not as described or the quality of the merchandise or services is different.', defendable: true,  timeframe: '18 days from receiving the NoC' },
                { reason_code: '13.4', reason: 'Counterfeit Merchandise', description: 'The merchandise was identified as counterfeit by a third party.', defendable: true,  timeframe: '18 days from receiving the NoC' },
                { reason_code: '13.5', reason: 'Misrepresentation', description: "The cardholder's bank received a notice from the cardholder claiming that the terms of the sale were misrepresented.", defendable: true,  timeframe: '18 days from receiving the NoC' },
                { reason_code: '13.6', reason: 'Credit not Processed', description: "The cardholder's bank received a notice from the cardholder claiming that they received a credit or voided transaction receipt that has not been processed.", defendable: true,  timeframe: '18 days from receiving the NoC' },
                { reason_code: '13.7', reason: 'Canceled Merchandise/Services', description: "The cardholder's bank received a notice from the cardholder stating that they returned merchandise or canceled services, but the credit has not appeared on the cardholder's Visa statement.", defendable: true,  timeframe: '18 days from receiving the NoC' },
                { reason_code: '13.8', reason: 'Original Credit Transaction Not Accepted', description: 'The original credit was not accepted.', defendable: false, timeframe: null },
                { reason_code: '13.9', reason: 'Non-Receipt of Cash or Load Transaction Value', description: 'The cardholder claims that they did not receive cash or only received partial cash at an ATM withdrawal machine.', defendable: false, timeframe: null },
            ],
            fraud: [
                { reason_code: '10.1', reason: 'EMV Liability Shift Counterfeit Fraud', description: 'The cardholder claims that they did not authorize or participate in a transaction that you processed. The terminal was not EMV compliant.', defendable: false, timeframe: null },
                { reason_code: '10.2', reason: 'EMV Liability Shift Non-Counterfeit Fraud', description: 'The cardholder claims that they did not authorize or participate in a transaction that you processed. The terminal was not EMV compliant.', defendable: false, timeframe: null },
                { reason_code: '10.3', reason: 'Other Fraud-Card Present Environment', description: 'The cardholder claims that they did not authorize or participate in a key-entered or unattended transaction conducted in a card-present environment.', defendable: true, timeframe: '18 days from receiving NoC' },
                { reason_code: '10.4', reason: 'Other Fraud-Card Absent Environment', description: 'The cardholder did not authorize or participate in a transaction conducted in a card-absent environment such as internet, mail-order, phone-order, or other options.', defendable: true, timeframe: '18 days from receiving NoC' },
                { reason_code: '10.5', reason: 'Visa Fraud Monitoring Program', description: "Visa notified the cardholder's bank that the Visa Fraud Monitoring Program (VFMP) identified the transaction and the cardholder's bank has not successfully disputed the transaction under another dispute condition.", defendable: false, timeframe: null },
            ],
            processing_errors: [
                { reason_code: '12.1',   reason: 'Late Presentment', description: 'The transaction was not sent to Visa within the required timeframe.', defendable: false, timeframe: null },
                { reason_code: '12.2',   reason: 'Incorrect Transaction Code', description: 'The cardholder claims that the converted amount of charge on an international transaction is incorrect.', defendable: true, timeframe: '18 days from receiving NoC' },
                { reason_code: '12.3',   reason: 'Incorrect Currency', description: 'You sent a transaction that was processed with an incorrect currency code. The cardholder was either not advised or did not agree that Dynamic Currency Conversion (DCC) would occur.', defendable: true, timeframe: '18 days from receiving NoC' },
                { reason_code: '12.4',   reason: 'Incorrect Account Number', description: "You either processed the transaction to an incorrect account number or did not authorise the transaction, and it was processed to an account number not on the issuer's master file.", defendable: true, timeframe: '18 days from receiving NoC' },
                { reason_code: '12.5',   reason: 'Incorrect Amount', description: 'The cardholder claims that the amount they agreed to pay differs from the amount charged.', defendable: true, timeframe: '18 days from receiving NoC' },
                { reason_code: '12.6.1', reason: 'Duplicate Processing', description: 'The cardholder claims that a single transaction was processed more than once, or they paid for the merchandise or service by other means such as cash, check, other card, and other options.', defendable: true, timeframe: '18 days from receiving NoC' },
                { reason_code: '12.6.2', reason: 'Paid by Other Means', description: 'The cardholder claims that a single transaction was processed more than once, or they paid for the merchandise or service by other means such as cash, check, other card, and other options.', defendable: true, timeframe: '18 days from receiving NoC' },
                { reason_code: '12.7',   reason: 'Invalid Data', description: 'An authorization was obtained using invalid or incorrect data.', defendable: false, timeframe: null },
            ],
        },
    }
}
            //{ reason_code: 4521, reason_code_us: 'A01', reason: '', description: '', defendable: false, timeframe: null },
