import React, { useEffect, useState, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectLocation } from '@/features/Locations/locationSlice'

import {
    selectAmountTowardsBalance,
    selectTipAmount,
    selectIsAmountValid,
    selectShouldOverProvision,
    selectChargebackTestData,
    setShouldOverProvision,
} from '@/features/AdvancedPointOfSale/advancedPointOfSaleSlice'

import AdyenCreditCard from '@/features/Adyen/AdyenCreditCard'
import Checkbox from '@/components/Form/Checkbox'
import PaymentForm from '../PaymentForm'
import { numberToCurrency } from '@/lib/Number'
import { isAdyenTest } from '@/lib/Adyen'

export default function Manual({
    self,
    totalDue,
    tabs,
    currentTab,
    hiddenSubmitButtonId='hidden-adyen-manual-submit-button',
    isProcessing,
    environment,
    onTabChange,
    onLoad,
    onSubmit,
}) {

    const dispatch             = useDispatch()
    const location             = useSelector(selectLocation)
    const tipAmount            = useSelector(selectTipAmount)
    const amountTowardsBalance = useSelector(selectAmountTowardsBalance)
    const shouldOverProvision  = useSelector(selectShouldOverProvision)
    const isAmountValid        = useSelector(selectIsAmountValid)
    const chargebackTestData   = useSelector(selectChargebackTestData)

    const [zipCode, setZipCode]             = useState(null)
    const [adyenCheckout, setAdyenCheckout] = useState(null)

    const amountToProcess = useMemo(() => (
        parseFloat(tipAmount || 0) + parseFloat(amountTowardsBalance || 0)
    ), [tipAmount, amountTowardsBalance])

    const processButton = useMemo(() => <>
        <Checkbox
            cols=''
            name='should_over_provision'
            label={`Over Authorize? (${location.adyen_pre_authorized_transaction_over_provision_percentage}%)`}
            value={shouldOverProvision}
            className='mr-4'
            labelClassName='text-uppercase font-weight-bold'
            asFormGroup={false}
            handleChange={() => dispatch(setShouldOverProvision(!shouldOverProvision))}
        />

        <button
            children={isProcessing ? 'Please Wait...' : `Process ${numberToCurrency(amountToProcess)}${isAdyenTest && !!chargebackTestData ? ` (${chargebackTestData})` : ''}`}
            className='btn btn-primary process-button'
            disabled={isProcessing || !isAmountValid || !zipCode}
            onClick={() => document.getElementById(hiddenSubmitButtonId).click()}
        />
    </>, [
        adyenCheckout,
        isProcessing,
        isAmountValid,
        tipAmount,
        zipCode,
        amountToProcess,
        hiddenSubmitButtonId,
        location,
        shouldOverProvision,
        chargebackTestData
    ])

    const handleSubmit = () => {
        onSubmit({
            totalReceivedAmount: amountToProcess,
            tipAmount: tipAmount || 0,
            zipCode: zipCode,
        })
    }

    useEffect(() => {
        onLoad(processButton)
    }, [processButton])

    return (
        <PaymentForm
            self={self}
            totalDue={totalDue}
            creditTabs={tabs}
            creditCurrentTab={currentTab}
            onCreditTabChange={onTabChange}
        >
            <AdyenCreditCard
                className='mt-4 mb-n2 pt-4 border-top border-thick border-color-gray4'
                locationId={location.id}
                locationTimeZone={location.time_zone}
                environment={environment}
                zipcode={zipCode}
                adyenCheckout={adyenCheckout}
                setAdyenCheckout={setAdyenCheckout}
                setZipCode={(e) => setZipCode(e.target.value)}
                onSubmit={handleSubmit}
            />

            <button
                id={hiddenSubmitButtonId}
                type='button'
                className='d-none'
                onClick={() => adyenCheckout.submit()}
            />
        </PaymentForm>
    )
}
