import React, { useEffect, useRef, useState } from 'react'
import axios from 'axios'
import Checkbox from '@/components/Form/Checkbox'
import FormRow from '@/components/Form/FormRow'
import Image from '@/components/Form/Image'
import Input from '@/components/Form/Input'
import Label from '@/components/Form/Label'
import Select from '@/components/Form/Select'

import {
    isAdvancedPointOfSaleEnabled,
    isAdvancedPointOfSaleInTransition,
    isAdvancedPointOfSaleDisabled,
} from '@/lib/AdvancedPointOfSale'

export default function AdvancedPointOfSaleSettings({
    form,
    errors,
    imagesUrl,
    directUploadUrl,
    superUser,
    companyAdmin,
    isAdvPosStatusMutable,
    handleUpdate,
    handleCheckboxUpdate,
    handleManualUpdate,
}) {

    const [previewImages, setPreviewImages] = useState({ currentReceiptLogo: null })
    const [imagesFetched, setImagesFetched] = useState(false)
    const [imagesLoading, setImagesLoading] = useState(false)
    const [warning, setWarning]             = useState(null)
    const originalStatus                    = useRef()

    useEffect(() => {
        // we don't do anything if we've already fetched the
        // images or if we have no url to fetch from (new record)
        if (imagesFetched || !imagesUrl) {
            return
        }

        // turn on spinners
        setImagesLoading(true)

        axios.get(imagesUrl).then(({ data }) => {
            setPreviewImages({
                currentReceiptLogo: data.adv_pos_receipt_logo,
            })
        }).catch((error) => {
                if (console) { console.warn('Unable to load images...', error) }
            }).finally(() => {
                setImagesLoading(false)
                setImagesFetched(true)
            })
    }, [imagesFetched])

    useEffect(() => {
        originalStatus.current = form.advanced_point_of_sale_status

        if ((superUser || companyAdmin) && !isAdvPosStatusMutable) {
            setWarning('Status cannot be changed due to outstanding open checks.')
        }
    }, [])

    return <>
        <h3 className='d-flex align-content-center justify-content-between'>
            <span>
                <span>Advanced Point of Sale</span>

                {
                    (superUser || companyAdmin) && isAdvancedPointOfSaleEnabled(originalStatus.current) && (
                        <span className='badge badge-success py-1 ml-3' style={{ fontSize: '0.8rem' }}>Enabled</span>
                    )
                }
                {
                    (superUser || companyAdmin) && isAdvancedPointOfSaleInTransition(originalStatus.current) && (
                        <span className='badge badge-warning py-1 ml-3' style={{ fontSize: '0.8rem' }}>In Transition</span>
                    )
                }
                {
                    (superUser || companyAdmin) && isAdvancedPointOfSaleDisabled(originalStatus.current) && (
                        <span className='badge badge-secondary py-1 ml-3' style={{ fontSize: '0.8rem' }}>Disabled</span>
                    )
                }
            </span>

            {
                (superUser || companyAdmin) ? (
                    <Select
                        cols='auto pr-0 mt-n2'
                        name='advanced_point_of_sale_status'
                        options={[
                            {
                                value: 'enabled',
                                display: 'Enabled',
                                disabled: (
                                    (isAdvancedPointOfSaleInTransition(originalStatus.current) && isAdvancedPointOfSaleEnabled(form.advanced_point_of_sale_status))
                                    || (isAdvancedPointOfSaleEnabled(originalStatus.current) && isAdvancedPointOfSaleEnabled(form.advanced_point_of_sale_status))
                                    || isAdvancedPointOfSaleDisabled(originalStatus.current)
                                )
                            },
                            { 
                                value: 'in_transition',
                                display: 'In-Transition',
                                disabled: (
                                    isAdvancedPointOfSaleInTransition(form.advanced_point_of_sale_status)
                                )
                            },
                            {
                                value: 'disabled',
                                display: 'Disabled',
                                disabled: (
                                    isAdvancedPointOfSaleDisabled(form.advanced_point_of_sale_status)
                                )
                            }
                        ]}
                        value={form.advanced_point_of_sale_status}
                        errors={errors}
                        validation={{ 'advanced_point_of_sale_status': { required: false } }}
                        disabled={!isAdvPosStatusMutable}
                        prepend
                        hideLabel
                        req
                        handleChange={handleUpdate}
                    >
                        <div className='input-group-prepend'>
                            <span className='input-group-text'>Status</span>
                        </div>
                    </Select>
                ) : (
                    <Checkbox
                        className='mt-2 mr-4'
                        cols=''
                        label=' '
                        name='advanced_point_of_sale_status'
                        tooltip={
                            isAdvancedPointOfSaleEnabled(form.advanced_point_of_sale_status, { allowTransition: true })
                                ? 'This feature is permanently enabled'
                                : 'Please contact us about enabling this feature'
                        }
                        value={isAdvancedPointOfSaleEnabled(form.advanced_point_of_sale_status, { allowTransition: true })}
                        disabled
                        readOnly
                    />
                )
            }
        </h3>

        {
            !!warning && (
                <div
                    className='alert alert-warning rounded font-weight-bold text-center m-0 mt-n2 mb-3 w-100'
                    children={warning}
                />
            )
        }

        {
            isAdvancedPointOfSaleEnabled(form.advanced_point_of_sale_status, { allowTransition: true }) && <>
                <FormRow>
                    <Input
                        cols='3'
                        label='Min. Pre-Authorized Amount'
                        value={form.minimum_pre_authorized_amount}
                        type='number'
                        min='1'
                        step='.01'
                        placeholder='0.00'
                        prepend
                        req
                        errors={errors}
                        validation={{ 'minimum_pre_authorized_amount': { required: true }}}
                        handleChange={handleUpdate}
                    >
                        <div className="input-group-prepend">
                            <div className="input-group-text">$</div>
                        </div>
                    </Input>

                    <Select
                        cols='3'
                        label='Pre-Auth Over-Provisioning'
                        name='adyen_pre_authorized_transaction_over_provision_percentage'
                        options={ [0, 5, 10, 20, 30, 40, 50].reverse().map((v) => ({ value: Number(v).toFixed(0), display: `${v}%` })) }
                        value={Number(form.adyen_pre_authorized_transaction_over_provision_percentage).toFixed(0)}
                        errors={errors}
                        validation={{ 'adyen_pre_authorized_transaction_over_provision_percentage': { required: true } }}
                        disabled={!(superUser || companyAdmin)}
                        req
                        handleChange={handleUpdate}
                    />

                    <Select
                        cols='3'
                        label='Pre-Auth Formula Estimated Tip'
                        name='adyen_pre_authorized_transaction_estimated_tip_percentage'
                        options={ [0, 5, 10, 20, 30, 40, 50].reverse().map((v) => ({ value: Number(v).toFixed(0), display: `${v}%` })) }
                        value={Number(form.adyen_pre_authorized_transaction_estimated_tip_percentage).toFixed(0)}
                        errors={errors}
                        validation={{ 'adyen_pre_authorized_transaction_estimated_tip_percentage': { required: true } }}
                        disabled={!(superUser || companyAdmin)}
                        req
                        handleChange={handleUpdate}
                    />

                    <Select
                        cols='3'
                        label='Pre-Auth Formula Estimated F&B'
                        name='adyen_pre_authorized_transaction_estimated_fab_percentage'
                        options={ [0, 5, 10, 20, 30, 40, 50].reverse().map((v) => ({ value: Number(v).toFixed(0), display: `${v}%` })) }
                        value={Number(form.adyen_pre_authorized_transaction_estimated_fab_percentage).toFixed(0)}
                        errors={errors}
                        validation={{ 'adyen_pre_authorized_transaction_estimated_fab_percentage': { required: true } }}
                        disabled={!(superUser || companyAdmin)}
                        req
                        handleChange={handleUpdate}
                    />
                </FormRow>

                <FormRow>
                    <div className='col-6 form-group row mx-0 pb-0'>
                        <Label name='Suggested Tip Brackets' className='col-12 px-0' req />

                        <Input
                            cols='4 pl-0'
                            value={form.suggested_tip_percentage_1}
                            type='number'
                            min='0'
                            step='1'
                            placeholder='18'
                            append
                            hideLabel
                            errors={errors}
                            validation={{ 'suggested_tip_percentage_1': { required: true }}}
                            handleChange={handleUpdate}
                        >
                            <div className="input-group-append">
                                <div className="input-group-text">%</div>
                            </div>
                        </Input>

                        <Input
                            cols='4 px-0'
                            value={form.suggested_tip_percentage_2}
                            type='number'
                            min='0'
                            step='1'
                            placeholder='20'
                            append
                            hideLabel
                            errors={errors}
                            validation={{ 'suggested_tip_percentage_2': { required: true }}}
                            handleChange={handleUpdate}
                        >
                            <div className="input-group-append">
                                <div className="input-group-text">%</div>
                            </div>
                        </Input>

                        <Input
                            cols='4 pr-0'
                            value={form.suggested_tip_percentage_3}
                            type='number'
                            min='0'
                            step='1'
                            placeholder='22'
                            append
                            hideLabel
                            errors={errors}
                            validation={{ 'suggested_tip_percentage_3': { required: true }}}
                            handleChange={handleUpdate}
                        >
                            <div className="input-group-append">
                                <div className="input-group-text">%</div>
                            </div>
                        </Input>
                    </div>

                    <Select
                        cols='6'
                        name='adv_pos_return_to_after_fulfillment'
                        label='Where To Return To After Ordering'
                        options={[
                            { is_blank: true, display: 'Previous' },
                            { value: 'OpenChecks', display: 'Open Checks' },
                        ]}
                        value={form.adv_pos_return_to_after_fulfillment}
                        errors={errors}
                        validation={{ 'adv_pos_return_to_after_fulfillment': { required: false } }}
                        req
                        handleChange={handleUpdate}
                    />
                </FormRow>

                <FormRow>
                    <Input
                        cols='6'
                        label='Session Timeout'
                        value={form.session_timeout_seconds}
                        type='number'
                        min='0'
                        step='1'
                        append
                        req
                        errors={errors}
                        validation={{ 'session_timeout_seconds': { required: false }}}
                        handleChange={handleUpdate}
                    >
                        <div className="input-group-append">
                            <small className="input-group-text">
                                {form.session_timeout_seconds == 1 ? 'Sec' : 'Secs'}

                                {
                                    form.session_timeout_seconds < 60
                                        ? ''
                                        : form.session_timeout_seconds > 60
                                            ? ' / ' + (form.session_timeout_seconds / 60).toFixed(2).replace('.00', '') + ' Mins'
                                            : ' / 1 Min'
                                }
                            </small>
                        </div>
                    </Input>

                    <Checkbox
                        className='pl-3 mt-4 pt-1'
                        cols='6'
                        label='Enable Retail Checks?'
                        name='adv_pos_enable_retail_checks'
                        value={form.adv_pos_enable_retail_checks}
                        handleChange={handleCheckboxUpdate}
                    />
                </FormRow>

                <hr className='mt-2' />

                <FormRow>
                    <div className='col-12'>
                        <Image
                            loading={imagesLoading}
                            name='adv_pos_receipt_logo'
                            label='Receipt Logo'
                            previewUrl={previewImages.currentReceiptLogo}
                            directUploadUrl={directUploadUrl}
                            tooltip='Maximum of 100 pixels in height, recommended 200 x 100 pixels'
                            handleChange={handleManualUpdate}
                        />
                    </div>
                </FormRow>
            </>
        }
    </>
}
